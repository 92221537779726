<script lang="ts" setup>
import { CableHoleType } from '~/types/CableHole'

const { nextTab } = useConfigNavigation()
const { caseConfig } = storeToRefs(useCaseConfig())

const options = [{
  title: 'Prostup s výřezem',
  value: CableHoleType.cutout,
}, {
  title: 'Protipožářní ucpávka',
  value: CableHoleType.seal,
}]

function changeType (type: CableHoleType) {
  caseConfig.value.cableHoleType = type
  caseConfig.value.cableHoles = []
}
</script>

<template>
  <div>
    <div flex flex-col items-center gap-12px text="14px black" leading-20px mt-8px>
      <template v-if="!caseConfig.isSz">
        <SelectablePill
          v-for="option in options" :key="option.value" :label="option.title"
          :is-active="option.value === caseConfig.cableHoleType" side-padding="24px" @click="changeType(option.value)"
        />
        <UnoIcon i-heroicons-solid-information-circle text="app-text-icon 40px" mt-8px />
        <h1 font-700 whitespace-pre-wrap text-center max-w-220px>
          Prosím umístěte prostupy v příslušném rozměru a umístění.
        </h1>
        <h2 font-500 whitespace-pre-wrap text-center max-w-254px mt-12px>
          Prostup je tvořen výřezem v požadované části
          skříně. Po protažení kabelů se výřez vyplní protipožární ucpávkou.
        </h2>
      </template>
      <template v-else>
        <h1 font-700 whitespace-pre-wrap text-center max-w-220px>
          Pro tento typ skříně se prostupy neupravují.
        </h1>
      </template>
    </div>
    <button type="button" app-btn w-full mt-24px @click="nextTab()">
      Pokračovat
    </button>
  </div>
</template>
